/**
 * This feature is not yet implemented
 */

import React from 'react'
import { Button, Logo, Text, Theme, Separator, ScrollView, YStack } from '@bp/ui'
import { useAuth } from 'app/lib/auth'

export function Home() {
  const { login, signup, logout, user, isLoading, error, accessToken, status } = useAuth()

  if (!user && !isLoading) {
    return (
      <Theme theme="dark">
        <ScrollView>
          <YStack gap="$7" alignItems="center" paddingHorizontal="$6" paddingTop="$20">
            <>
              <Logo scale={3} />
              <Separator />
              {error ? <Text>Error: {error?.message}</Text> : null}

              {error ? (
                <Button
                  variant="primary"
                  onPress={() => {
                    logout()
                  }}
                >
                  Logout
                </Button>
              ) : (
                <>
                  <Button
                    width={250}
                    onPress={() => {
                      login()
                    }}
                    variant="primary"
                  >
                    Login
                  </Button>
                  <Button
                    variant="accent"
                    width={250}
                    onPress={() => {
                      signup()
                    }}
                  >
                    Signup
                  </Button>
                </>
              )}
            </>
          </YStack>
        </ScrollView>
      </Theme>
    )
  }

  return null
}
